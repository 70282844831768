@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@100;400;500;600&display=swap');

h1 {
  font-family: 'Poppins', sans-serif;	
}


#about {
  height: 100%;  
  width: 100%;  
}

.header {    
  height: auto;
  width: 95%;
  margin: auto;
  height: 45vh;
  overflow: hidden;
}

.header h1 {  
  margin-top: 20px;
  margin-bottom: 20px;
  font-family: 'Poppins', sans-serif;	
  font-weight: 500;  
}
.header img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  object-position: center
}

.content {
  display: flex;   
  flex-direction: column;  
  width: 95%;
  margin: auto;      
}
.content .description {
  font-size: 20px;  
  margin-top: 30px;
  margin-bottom: 30px;
}
.content .features {
  margin-bottom: 30px;
}
.content .features h1 {
  font-weight: 500;   
  font-size: 22px; 
  font-family: 'Poppins', sans-serif;	
  margin: 30px 0;
}
.content .features ul {
  list-style: none;
  padding-left: 0;    
}
.content .features li {
  margin: 20px 0;
  display: flex;
}
.content .features ul li img {
  padding-right: 20px;  
  align-self: flex-start;
  margin-top: 4px;  
}

@media only screen and (min-width: 930px) {
  .header {
    display: flex;            
    height: 30vh;
  }
  .header h1 { 
    width: 40%;  
    align-self: flex-end;   
    margin: 0;      
    padding-left: 20px;  
  }
  .header img {
    width: 60%;    
  }
}
@media only screen and (min-width: 1200px) {
  .header {
    height: 37vh;
    max-width: 1700px;
  }  
  .header h1 { 
    width: 30%;          
    font-size: 45px;
    padding-left: 50px;
  }
  .header img {
    width: 70%;    
  }
}

@media only screen and (min-width: 930px) {  
  .content {
    padding-left: 20px;
    flex-direction: row;
  }
  .content .description {
    flex-basis: 50%;
  }
  .content .features {    
    flex-basis: 45%;
    margin-left: auto;    
  }
  .content .features ul li {
  }
}
@media only screen and (min-width: 1200px) {
  .content {
    padding-left: 50px;
  }  
  .content .features {    
    flex-basis: 40%;
  }
}
@media only screen and (min-width: 1500px) {
  .content .features {    
    flex-basis: 35%;
  }
}


.title {
  font-size: 60px;
  font-weight: 500;
  text-align: center;
  margin: 8vh 0;
}

.contentWrapper {
  display: flex;
  width: 75%;  
  margin: auto;
  height: 50vh;  
  margin-bottom: 20vh;
  flex-direction: column;  
}
.contentWrapper h1 {
  font-size: 50px;
  font-weight: 500;
  margin-bottom: 0;  
}
.contentWrapper h1:before {
  position: absolute;
  width: 150px;
  height: 60px;
  border-bottom: 2.2px solid darkblue;    
  content: ""    
}
.contentWrapper .content {
  display: flex;
  flex-direction: row;   
  width: 100%; 
  height: 65%; 
}
/* .content > * {
  width: 50%;     
  border: 1px solid red;
} */
.content .text {
  width: 50%;
}
.content .image {    
  width: 50%;
  display: flex;    
  justify-content: right;  
}
.content .image img {  
  /* width: 85%;
  height: 65%;    */
  width: 85%;
  height: 100%;
  object-fit: cover;      
  box-shadow: 9px 10px 17px -5px rgba(0, 0, 0, 0.49);
  -webkit-box-shadow: 9px 10px 17px -5px rgba(0, 0, 0, 0.49);
  -moz-box-shadow: 9px 10px 17px -5px rgba(0, 0, 0, 0.49);
  margin-top: auto;  
  margin-bottom: auto;  
}
.content .text {
  font-size: 25px;
  margin-top: auto;
  /* margin-bottom: auto;   */
}
.contentWrapper .button {  
  margin-top: auto;  
  font-size: 30px;
  font-weight: bold;
  padding: 10px;
  border-radius: 25px;
  border-width: 0;
  transition: 0.3s;
  width: 20%;
  background-color: #16375d;
  color: white;
}
.contentWrapper .button:hover {
  background-color: white;
  color: black;
}


/* .content-text {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}
.content-text h1 {
  font-size: 50px;
  font-weight: bold;
}
.content-text h1:before {
    position: absolute;
    width: 150px;
    height: 60px;
    border-bottom: 2.2px solid darkblue;    
    content: ""    
}
.content-text p {
  font-size: 24px;
}
.content-text button {
  font-size: 30px;
  font-weight: bold;
  padding: 10px;
  border-radius: 25px;
  border-width: 0;
  transition: 0.3s;
  width: 37%;
  background-color: #16375d;
  color: white;
}
.content-text button:hover {
  background-color: white;
  color: black;
} */

/* .image {
  display: flex;
}
.image img {
  width: 80%;
  height: 65%;   
  object-fit: cover;
  margin: 8%;
  box-shadow: 9px 10px 17px -5px rgba(0, 0, 0, 0.49);
  -webkit-box-shadow: 9px 10px 17px -5px rgba(0, 0, 0, 0.49);
  -moz-box-shadow: 9px 10px 17px -5px rgba(0, 0, 0, 0.49);
} */
