@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@100;400;500;600&display=swap');

h1 {
  font-family: 'Poppins', sans-serif;	
}

.careers {
  display: flex;
  flex-direction: column;
}

.header {
  height: auto;
  width: 95%;
  margin: auto;  
  max-width: 1700px;
  height: 45vh;
  overflow: hidden;
}

.header h1 {
  margin-top: 20px;
  margin-bottom: 20px;
  font-family: "Poppins", sans-serif;
  font-weight: 500;
}
.header img {
  width: 100%;
  height: 100%;  
  object-fit: cover;  
}

.description {
  width: 95%;
  display: inline-block;
  margin-left: auto;
  margin-right: auto;
  font-size: 20px;
  margin-top: 30px;
  margin-bottom: 30px;
  max-width: 1700px;
  margin-bottom: 10vh;
}

ul.careerList {
  padding: 0;
  display: flex;
  flex-direction: column;  
  width: 95%;
  max-width: 600px;
  margin: auto;
  gap: 100px;
  margin-bottom: 100px;  
}
ul.careerList .career {
  display: flex;
  flex-direction: column;
  gap: 30px;    
}
ul.careerList .career div {
  display: flex;  
  flex-direction: column;
}
ul.careerList .career div h1 {
  font-weight: 500;
  margin-bottom: 20px;
  text-decoration: underline;  
}
ul.careerList .career div span {
  font-size: 16px;    
  font-weight: 400;  
  font-family: "Poppins", sans-serif;
}
ul.careerList .career img {
  max-width: 600px;
  object-fit: cover;    
}


.benefitsWrapper {
  margin-bottom: 100px;
  max-width: 1700px;  
  width: 95%;
  margin: auto;
  margin-bottom: 100px;
}

.benefitsWrapper h1 {
  margin-right: 10px;  
  text-align: center;
  font-weight: 400;
  padding-bottom: 50px;   
  /* margin-left: 10px;   */
  padding-left: 20px;  
  width: 95%;  
}

.benefitsWrapper ul {  
  display: grid;  
  margin-left: auto;
  margin-right: auto;  
  margin-bottom: 0;
  padding-left: 0;  
  padding-left: 0;
  margin-left: 0;
  margin: 0 10px      
}

.benefitsWrapper ul li {    
  margin: 50px 0;      
  display: grid;
  grid-template-columns: 45px auto;    
  gap: 30px;
  width: max-content;  
}
.benefitsWrapper ul li img {
  height: 45px;
  width: 45px;
  margin-right: auto;   
}
.benefitsWrapper ul li span {  
  margin: auto;
  margin-left: 0;
  font-size: 20px;      
  /* font-size: 25px;     */
  margin-right: auto;  
}


.applyWrapper {  
  max-width: 1700px;  
  width: 95%;
  margin: auto;    
}

.applyWrapper h1 {
  padding-bottom: 75px;
  text-align: center;
  font-weight: 400;  
  padding-left: 20px;  
  width: 95%;
  margin: 0;
}

.form {
  display: grid;
  grid-template-columns: 1fr 1fr;    
  width: 90%;         
  max-width: 700px;    
  margin: auto;  
  row-gap: 20px;  
  margin-bottom: 10vh; 
}
.form > div {    
  display: flex;    
  width: 90%;
  flex-direction: column;
  /* border: 1px solid red; */
}
.form > div:nth-of-type(2), .form > div:nth-of-type(4) {
  /* margin-left: auto;  */
}

.form > .required input {  
  display: inline-block;    
  width: 100%;    
  padding-left: 20px;  
  margin-bottom: 10px;
}
.form > .required::before {
  content: '*';    
  position: relative;  
  display: inline;
  width: fit-content;     
  color: red;
  font-size: 25px;  
  height: 0px;  
  top: 12px;      
}

.form div input, .form textarea, .form button {
  border-width: 0px;
  border-bottom: 2px solid black;
  font-size: 16px;  
  padding: 15px 10px;   
  border-radius: 0;
}
.form textarea {
  margin-bottom: 10px;
}
.form .required .error {
  height: 100%;  
}


.form textarea {
  grid-column: 1 / span 2;     
  border: 1.5px solid black;
  margin-top: 40px;
  height: 17vh;  
  resize: none;
}
.form button {
  transition: 0.3s;
  border-width: 0;
  font-size: 18px;
  border: 1.5px solid black;   
  width: 80%;
  font-weight: 500;
  color: white;  
  border-color: white;
  grid-column: 1 / span 2; 
  width: 32%;
  min-width: 150px;
  max-width: 135px;
  margin-top: 10px;      
}
.form > :nth-last-child(1):hover { 
  background-color: white;
  color: black;
}

@media only screen and (min-width: 430px) {
  .benefitsWrapper ul {
    margin-left: 10%;
  }
}

@media only screen and (min-width: 930px) {
  .header {
    display: flex;
    height: 30vh;
  }
  .header h1 {    
    align-self: flex-end;
    margin: 0;
    padding-left: 20px;
    padding-right: 5vw;
  }
  .header img {
    width: 60%;
    flex-grow: 1;
  }
  .description {
    padding-left: 20px;
  }
  ul.careerList {
    max-width: none;    
    max-width: 1390px; 
  }
  ul.careerList .career {
    flex-direction: row;                                    
  }
  ul.careerList .career div span {
    font-size: clamp(16px, 1.7vw, 19px);    
  }
  ul.careerList .career div, ul.careerList .career img {  
    width: 50%;    
    max-width: 600px;
  }  
  ul.careerList .career img {
    object-fit: cover;    
    height: 40vh;     
    margin-right: auto;;           
  }
  
  .benefitsWrapper h1 {
    text-align: left;
    /* margin-left: 5%; */
    /* margin: auto; */
    width: 95%;
    padding-left: 20px;
  }
  .listWrapper {
    display: flex;
    max-width: 750px;                 
    /* margin-left: 5%; */
    margin: auto;
  }
  .benefitsWrapper ul {
    width: fit-content;    
    display: inline-block;  
    margin-left: 0%;          
  }
  .benefitsWrapper .right {
    margin-left: auto;
    margin-right: 0;
  }
  
  .applyWrapper h1 {
    text-align: left;
    width: 95%;
    padding-left: 20px;
    /* margin: auto; */
  }
  .applyWrapper .form {
    margin-left: 15%;       
  }

  /* .benefitsWrapper ul {
    flex-direction: row;    
    flex-wrap: wrap;                
    column-gap: 100px;
  }    
  .benefitsWrapper ul li img {
    text-align: center;    
  }
  .benefitsWrapper ul li:nth-child(even) {
    margin-left: auto;
  } */
}
@media only screen and (min-width: 1200px) {
  .header {
    height: 37vh;    
  }
  .description {
    padding-right: 300px;
  }
  ul.careerList .career {
    /* gap: 300px; */
  }
  ul.careerList .career img {
    padding-left: 3vw;
    padding-right: 3vw;
    padding-bottom: 3vw;
  }
  .listWrapper {
    display: flex;
    max-width: 900px;     
    margin: 0;
    margin-left: 15%;
  }
}

