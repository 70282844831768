$font-family-sans-serif: "Bungee Spice", sans-serif;
@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@100;400;500;600&display=swap');

// Import all of Bootstrap's CSS
@import "~bootstrap/scss/bootstrap";


// mobile

.navbar {
  margin: 10px;
}
.navbar-inner {
  max-width: 1500px;  
}

.logo {
  width: 150px;
}
.nav {
	text-align: center;	
}
#nav-wrapper a.nav-link {  		
  font-size: 17px;
  font-family: 'Poppins', sans-serif;	
  color: black;
  border-bottom: 1px solid black;  
}
.options-container {
  margin-top: 30px;
  margin-bottom: 15px;
}
.btn.quote-btn {
  background-color: black;
  border: 0px solid white;
  margin-left: auto;  
  display: inline-block;
  margin-top: auto;
  margin-right: 10px;  
  font-family: 'Poppins', sans-serif;	
  margin-right: 13px;
  text-decoration: none;
  color: white;
  font-size: 15px;
  width: auto;
  border-width: 1px;
}
.btn.quote-btn:hover {
  color: black;
  border: 1px solid black;  
  background-color: transparent;  
}
.options-container .option {    
  background-color: white;
  color: black;
  border: 0px solid white;
  font-size: 15px;
  font-family: 'Poppins', sans-serif;	  
}
.options-container .option:hover {      
  color: grey;  
  background-color: white;
}

.options-container img.globe{
  width: 15px;
}

@media screen and (min-width: 525px) {
  .btn.quote-btn {
    font-size: 20px;
  }
  .options-container .option {
    font-size: 18px;
  }
}


// tablet

@media screen and (min-width: 1200px) {
  #navWrapper {  		    
    border-bottom: 1px solid white; 
    border: 1px solid red;     
  }
  .options-container {
    margin-top: 15px;
    justify-content: left;
  }
}

// laptop




// .quote-btn {
// }

// .navlink a {
//   color: red;
// }

// .navbar-collapse {
//   justify-content: flex-end;
// }
// img.logo-custom {
//   height: clamp(60px, 4vw, 80px);
//   width: auto;
// }
// li.nav-item.quote-btn-custom a {
//   padding: 0.5em 2em;
//   border-radius: 50px;
// }
// li.nav-item {
//   display: flex;
//   padding: 0 1vw;
// }
// a.nav-link {
//   margin: auto;
//   font-size: clamp(14px, 1vw, 20px);
// }

// @media (max-width: 1200px) {
//   .container-lg,
//   .container-md,
//   .container-sm,
//   .container {
//     max-width: 90%;
//   }
//   a.nav-link {
//     font-size: 1.4vw;
//   }
//   li.nav-item.quote-btn-custom a {
//     font-size: 1.4vw;
//   }
// }

// @media (min-width: 1200px) {
//   a.nav-link {
//     margin: auto;
//     font-size: 1.2vw;
//   }
//   li.nav-item.quote-btn-custom a {
//     padding: 0.5em 2em;
//     font-size: 1.2vw;
//   }
// }
// @media (max-width: 991px) {
//   .container-lg,
//   .container-md,
//   .container-sm,
//   .container {
//     max-width: 100%;
//   }
// }

// @media (max-width: 991px) {
//   a.nav-link,a.nav-link:focus,a.nav-link:active,a.nav-link:hover   {
//     margin: auto;
//     font-size: clamp(18px, 2vw, 25px);
//     background-color: transparent;
//     border: none;
//   }
//   li.nav-item.quote-btn-custom a {
//     padding: 8px 0;
//     font-size: clamp(18px, 2vw, 25px);
//   }
//   li.nav-item {
//     background-color: white;
//     border: 0.5px solid black;
//     text-align: left;
//   }
//   li.nav-item a, a.nav-link:hover {
//     margin-left: 20px;
//     color: black;
//     font-weight: bold;
//   }
//   a.nav-link:hover {
//     color: #0275d8;
//   }
//   .navbar-nav .nav-link.active {
//     color: #0275d8;
//   }
//   .navbar-dark {
//     padding-bottom: 0;
//   }
// }

// .custom-toggler.navbar-toggler {
//   border-width: 0;
// }
// .navbar-toggler:focus,
// .navbar-toggler:active,
// .navbar-toggler-icon:focus {
//     outline: none;
//     box-shadow: none;
// }
// .custom-toggler .navbar-toggler-icon {
//   background-image: url(
// "data:image/svg+xml;charset=utf8,%3Csvg viewBox='0 0 32 32' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath stroke='rgba(255, 255, 255, 1)' stroke-width='2' stroke-linecap='round' stroke-miterlimit='10' d='M4 8h24M4 16h24M4 24h24'/%3E%3C/svg%3E");
// }

// .container-custom, .inner-container-custom, .container-fluid {
//   padding: 0;
// }
// .logo-custom  {
//   margin-left: 12px;
// }
// .custom-toggler.navbar-toggler {
//   margin-right: 12px;
// }
