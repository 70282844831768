@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@100;400;500;600&display=swap");

.footer .icons h1,
.footer .links li:first-of-type,
.footer > .icons h1,
.footer .contact li:first-of-type {
  font-size: 17px;
  font-weight: 600;
  font-family: "Poppins", sans-serif;
}
.footer .links li a,
li,
.footer .contact li:first-of-type,
.footer .contact ul li {
  font-size: 17px;
  font-family: "Poppins", sans-serif;
}

/* ------------------------footer */

.footerWrapper {
  background-color: white;     
  border: 0.5px solid black;
  /* width: 85%; */
  /* max-width: 1600px; */
  width: 100%;
}

.footer {
  margin: auto;
  display: flex;
  justify-content: space-between;
  width: 90%;  
  max-width: 975px;  
  flex-direction: column;
  padding: 30px 30px;  
}

/*  brand */
.footer .brand {
  display: flex;
  flex-direction: column;
  align-items: center;
}
.footer .brand .icons {
  display: none;
}

.footer .brand img {
  width: 200px;
  height: auto;
  margin-bottom: 20px;
}

.active {
  color: #017de9;
}
.nonActive {
  color: black;
}

/* .footer .brand ul {
  padding-left: 0;
  display: flex;
}
.footer .brand ul img {
  width: 15%;
  margin-right: 10%;
  transition: 0.1s;
} */
.footer .brand ul img:hover {
  filter: invert(48%) sepia(77%) saturate(1534%) hue-rotate(191deg)
    brightness(100%) contrast(102%);
  cursor: pointer;
}

/*  links */

.footer .links {
  margin-bottom: auto;
}
.footer .links ul {
  padding-left: 0;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  /* margin-bottom: auto; */  
}
.footer .links li {
  list-style: none;
  margin-bottom: 10px;  
}
.footer .links li:last-of-type {
  margin-bottom: 0;
}
.footer .links li a,
li {
  text-decoration: none;
  color: black;
  transition: 0.3s;
  font-size: 15px;
}
.footer .links li a:hover {
  color: #4598f7;
}

/*  contact */

.footer .contact ul {
  padding-left: 0;
  list-style: none;
}
.footer .contact ul li {
  color: black;
  margin-bottom: 10px;
  font-size: 15px;
}

/*  contact */

.footer > .icons {
  padding-left: 0;
}
.footer > .icons h1 {
  margin-top: auto;
  color: black;
  margin-bottom: 15px;
}
.footer > .icons div {
  display: flex;
}
.footer > .icons div :nth-child(2) {
  margin: 0 15px;
}
.footer > .icons img {
  width: 35px;
}
.footer > .icons img:hover {
  filter: invert(41%) sepia(84%) saturate(6711%) hue-rotate(195deg)
    brightness(98%) contrast(98%);
}

.copyright {  
  display: flex;  
}
.copyright span {    
  /* margin: auto; */
  text-align: center;
  margin: 20px auto;
}

@media only screen and (min-width: 850px) {
  .footerWrapper {
    /* width: 90%; */
    margin: auto;
  }
  .footer .brand .icons {
    display: block;
  }
  .footer > .icons {
    display: none;
  }
  .footer {
    flex-direction: row;
    width: 99%;
  }
  .footer .brand {
    align-items: flex-start;
  }
  .footer .brand div {
    margin-top: auto;
  }
  .footer .icons {
    margin-top: auto;
    margin-bottom: 0;
    width: 100%;
  }
  .footer .icons h1 {
    margin-bottom: 15px;
  }
  .footer .brand .icons div :nth-child(2) {
    margin: 0 15px;
  }

  .footer .brand ul {
    padding-left: 0;
  }
  .footer .brand ul img {
    width: 35px;
  }
  .footer .brand ul.icons div a:nth-child(2) {
    margin: 0 15px;
  }
}

@media only screen and (min-width: 1100px) {
  .footerWrapper {    
    width: 100%;
    margin: auto;
  } 
}