.title {
  font-size: 60px;
  font-weight: 500;
  text-align: center;
  /* margin: 8vh 0; */
  margin-top: 8vh;
  margin-bottom: 4vh;
}

.contentWrapper {
  display: flex;
  width: 75%;
  margin: auto;
  height: 50vh;
  margin-bottom: 20vh;
  flex-direction: column;  
  /* background-color: thistle;   */
  /* justify-content: space-between; */
}
.contentWrapper h1 {
  font-size: 50px;
  font-weight: bold;
  margin-bottom: 0;
}
.contentWrapper h1:before {
  position: absolute;
  width: 150px;
  height: 60px;
  border-bottom: 2.2px solid darkblue;
  content: "";
}
.contentWrapper .content {
  display: flex;
  flex-direction: row;
  width: 100%;
  height: 65%;
}
/* .content > * {
    width: 50%;     
    border: 1px solid red;
  } */
.content .text {
  width: 50%;
}
.content .image {
  width: 50%;
  display: flex;
  justify-content: right;
}
.content .image img {
  /* width: 85%;
    height: 65%;    */
  width: 85%;
  height: 100%;
  object-fit: cover;
  box-shadow: 9px 10px 17px -5px rgba(0, 0, 0, 0.49);
  -webkit-box-shadow: 9px 10px 17px -5px rgba(0, 0, 0, 0.49);
  -moz-box-shadow: 9px 10px 17px -5px rgba(0, 0, 0, 0.49);
  margin-top: auto;
  margin-bottom: auto;
}
.content .text {
  font-size: 25px;
  margin-top: auto;
  /* margin-bottom: auto;   */
}
.contentWrapper .button {
  margin-top: auto;
  font-size: 30px;
  font-weight: bold;
  padding: 10px;
  border-radius: 25px;
  border-width: 0;
  transition: 0.3s;
  width: 20%;
  background-color: #16375d;
  color: white;
}
.contentWrapper .button:hover {
  background-color: white;
  color: black;
}

.formWrapper {
  /* height: 60vh; */
  background-color: rgb(233, 233, 233);
  text-align: center;
  display: flex;
  flex-direction: column;
  padding: 2vh 0;
  margin-bottom: 4vh;
}
.formWrapper h1 {
  font-size: 40px;
  margin-top: auto;
}
.formWrapper span {
  font-size: 16px;
}
.form {
  display: grid;
  grid-template-columns: repeat(6, 1fr);
  width: 50%;
  height: 70%;
  margin: auto;
  gap: 10px;
}
.form > * {
  border-radius: 5px;
  border-width: 1px;
  font-size: 16px;
  padding: 15px 10px;
  grid-column: span 3;
}
.form label {
  text-align: left;
  padding: 0;
  padding-top: 15px;
  font-weight: normal;
  font-size: 18px;
}
.form > .spanAll {
  grid-column: span 6;
}
.form > .spanSome {
  grid-column: span 2;
}
.form button {
  grid-column: 1 / span 2;
  background-color: #3e98ff;
  border-radius: 50px;
  color: white;
  transition: 0.3s;
  border-width: 0;
  font-size: 20px;
}
.form button:hover {
  background-color: rgb(233, 233, 233);
  color: black;
}

/* .form > :nth-last-child(2) {
  grid-column: 1 / span 2;
}
.form > :nth-last-child(1) {
  grid-column: 1 / span 2;
  background-color: #3e98ff;
  border-radius: 50px;
  color: white;
  transition: 0.3s;
  border-width: 0;
  font-size: 20px;
}
.form > :nth-last-child(1):hover {
  background-color: rgb(233, 233, 233);
  color: black;
} */
