@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@100;400;500;600&display=swap");

h1, p {
  font-family: 'Poppins', sans-serif;	
}
textarea:focus, input:focus{
  outline: none;
}


.contactWrapper {
  display: flex;
  flex-direction: column;
}

.title {  
  padding: 10vh 0;
  text-align: center;
  margin-bottom: 2vh;
  font-weight: 500;
  font-size: 45px;
}
.subTitle {
  font-size: 25px;
  width: 80%;
  margin-left: auto;
  margin-right: auto;
}


.form {
  display: grid;
  grid-template-columns: 1fr 1fr;    
  width: 90%;         
  max-width: 700px;    
  margin: auto;  
  row-gap: 20px;    
  margin-bottom: 10vh;   
}
.form > div {    
  display: flex;    
  width: 90%;
  flex-direction: column;
  /* border: 1px solid red; */
}
.form > div:nth-of-type(2), .form > div:nth-of-type(4) {
  /* margin-left: auto;  */
}

.form > .required input {  
  display: inline-block;    
  width: 100%;    
  padding-left: 20px;  
  margin-bottom: 10px;
}
.form > .required::before {
  content: '*';    
  position: relative;  
  display: inline;
  width: fit-content;     
  color: red;
  font-size: 25px;  
  height: 0px;  
  top: 12px;      
}

.form div input, .form .textarea, .form button {
  border-width: 0px;
  border-bottom: 2px solid black;
  border-radius: 0;
  font-size: 16px;  
  padding: 15px 10px;   
}
.form textarea {
  margin-bottom: 10px;
}
.form .required .error {
  height: 100%;      
}

.form .textArea {
  grid-column: 1 / span 2;    
  width: 100%;
}
.form textarea {
  /* grid-column: 1 / span 2;      */
  width: 100%;  
  border: 1.5px solid black;
  /* margin-top: 40px; */
  height: 17vh;  
  resize: none;
  padding: 10px 10px;     
}
.form button {
  transition: 0.3s;
  border-width: 0;
  font-size: 18px;
  border: 1.5px solid black;   
  width: 80%;
  font-weight: 500;
  color: white;  
  border-color: white;
  grid-column: 1 / span 2; 
  width: 32%;
  min-width: 150px;
  max-width: 135px;
  margin-top: 10px;      
}
.form > :nth-last-child(1):hover { 
  background-color: white;
  color: black;
}

.content {    
  width: 100%;
  max-width: 700px;    
  height: auto;
  margin: 0 auto;  
  margin-bottom: 15vh;  
  display: flex;  
  flex-direction: column;
  gap: 5vh;  
  padding-left: 10px;  
  margin-left: auto;  
  margin-right: auto;
  width: fit-content;
}
.text {
  display: flex;
  flex-direction: column;
  justify-content: space-between;      
}
.text .item {
  padding: 10px 0;
  display: flex;
  flex-direction: column;    
}
.text .item div {
  display: flex;    
}
.text .item img {
  width: 30px;
  margin-bottom: auto;
}
.text .item label {
  font-size: 18px;
  font-weight: 700;
  margin-left: 10px;
  margin-right: 20px;
}
.text .item span {
  font-size: 18px;
  margin: 10px 0;
}

.image {
  background-size: cover;      
  width: 40%;
  margin-left: auto;
}

@media only screen and (min-width: 650px) {  
  .content {        
    align-items: baseline;          
  }
  .text .item {    
    flex-direction: row;    
    display: block;   
  }    
  .text .item div {    
    flex-direction: row;
    display: inline;
  }
  .text .item div > * {
    margin-top: auto;
    margin-bottom: auto;
  }
  .text .item span {          
    margin: 30px 0;            
    width: auto;    
  }
}

@media only screen and (min-width: 1400px) {  
  .contactWrapper {    
    flex-direction: row;    
    max-width: 1500px;
    margin: auto;    
  }
  .form {
    margin-top: 0;
    width: 50%;
    padding-bottom: 10vh;
  }
  .content {
    margin-top: 0;
  }
}