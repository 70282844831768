@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@100;400;500;600&display=swap");

h1, p {
  font-family: 'Poppins', sans-serif;	
}

.header {
  display: flex;
  flex-direction: column;
  margin-top: 10vh;    
  margin-right: auto;
  margin-left: auto; 
}
.title {  
  font-family: "Poppins", sans-serif;
  text-align: center;  
  font-weight: 500;
  font-size: 45px;
  width: 90%;
  margin-left: auto;
  margin-right: auto;
  margin-bottom: 5vh;    
}
.subTitle {
  font-size: 20px;  
  margin-left: auto;
  margin-right: auto;
  text-align: center;  
  width: 95%;    
  margin-bottom: 15vh;
  max-width: 1172px;
}
.cardsList {
  display: grid;
  margin: 0 auto;
  place-content: center;
  margin-bottom: 15vh;
  grid-template-columns: 1fr;
  margin: 5vh;
  justify-items: center;  
  margin: auto; 
}
.card {
  display: flex;
  flex-direction: column;  
  cursor: pointer;  
  height: auto;  
  width: 95%;    
  max-width: 400px;
  margin-bottom: 10vh;  
  text-decoration: none;
  color: black;
  transition: 0.1s;
}
.card:hover {
  opacity: 0.8;
  color: black;
}

.card .image {
  height: 25vh;
  width: 100%;  
  background-size: cover;  
  background-position: center;  
}
.card.special {
  display: flex;  
}
.card.special .image {
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
}
.card h1 {
  margin-top: 20px;
  font-size: 30px;
  font-weight: 500;
}
.card p {
  font-size: 17px;
}

.buttonsWrapper {	
	text-align: center;
	margin-bottom: 100px;
}
.buttonsWrapper h1 {
	margin-left: 25px;
	margin-right: 25px;
	margin-bottom: 30px;
}
.buttonsWrapper .buttons button {
  margin: 8px;
  /* font-weight: bold;   */
}
.buttonsWrapper .buttons button:nth-child(1) {
  background-color: #0275d8;
  color: white;
}
.buttonsWrapper .buttons button:nth-child(1):hover {
  color: black;
  background-color: white;
}
.buttonsWrapper .buttons button:nth-child(2) {
  background-color: black;
  color: white;
}
.buttonsWrapper .buttons button:nth-child(2):hover {
  color: black;
  background-color: white;
}

.serviceBtn {
  margin-top: 20px;
  margin-bottom: 20px;  
  display: inline-block;      
}
.serviceBtn {
  background-color: #0275d8;
  color: white;
  margin-bottom: 0;
}
.serviceBtn:hover {
  color: black;
}

.buttonsWrapper .buttons .quoteBtn {
  margin: 8px;  
}
.buttonsWrapper .buttons .quoteBtn:nth-child(1) {
  background-color: #0275d8;
  color: white;
}
.buttonsWrapper .buttons .quoteBtn:nth-child(1):hover {
  color: black;
  border: 1px solid #0275d8;  
  background-color: transparent;  
}
.buttonsWrapper .buttons .quoteBtn:nth-child(2) {
  background-color: black;
  color: white;
}
.buttonsWrapper .buttons .quoteBtn:nth-child(2):hover {
  color: black;
  border: 1px solid black;  
  background-color: transparent;  
}

@media only screen and (min-width: 900px) {
  .cardsList {
    grid-template-columns: 1fr 1fr;
    max-width: 1000px;    
  }   
}
@media only screen and (min-width: 1260px) {  
  .buttonsWrapper .buttons .quoteBtn {
		font-size: 25px;
    margin: 20px;    
  }  
}
@media only screen and (min-width: 1300px) {
  .cardsList {
    grid-template-columns: 1fr 1fr 1fr;
    max-width: 1500px
  }
}